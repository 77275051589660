<template>
    <Main>
        <Tabs :tabs="tabs" v-model="view"/>

        <CardList v-if="isLoading" :columns="3">
            <Placeholder element="li" :classes="[`card column-1`]" :height="90"/>
            <Placeholder element="li" :classes="[`card column-1`]" :height="90"/>
            <Placeholder element="li" :classes="[`card column-1`]" :height="90"/>
            <Placeholder element="li" :classes="[`card column-1`]" :height="90"/>
            <Placeholder element="li" :classes="[`card column-1`]" :height="90"/>
            <Placeholder element="li" :classes="[`card column-1`]" :height="90"/>
        </CardList>
        <template v-else>
            <template v-if="users.length && view === 'users'">
                <Tabs :tabs="filters" v-model="filter"/>

                Viser: {{ filteredUsers.length }} brugere

                <CardList :columns="3">
                    <Card v-for="user in filteredUsers" :key="user.id" @click="viewUser(user)">
                        <template #headline>{{ user.firstname }} {{ user.lastname }}</template>
                        <p>{{ user.email }}</p>
                        <ActionButton icon="signout" :small="true" :float="true" @click="loginAsUser(user.id)"/>
                    </Card>
                </CardList>
            </template>
            <CardList v-if="view === 'admins'" :columns="3">
                <Card v-for="admin in admins" :key="admin.id" @click="viewUser(admin)">
                    <template #headline>{{ admin.firstname }} {{ admin.lastname }}</template>
                    <p>{{ admin.email }}</p>
                </Card>
            </CardList>
        </template>
    </Main>

    <ModelRouterView/>
</template>

<script>
import Main from '@/components/Main.vue';
import ActionButton from '@/components/buttons/ActionButton.vue';
import { auth, httpsRequest, usersCollection } from '@/utils/firebase';
import useState from '@/store';
import CardList from '@/components/CardList.vue';
import Card from '@/components/Card.vue';
import useUnsubscribe from '@/utils/useUnsubscribe';
import Placeholder from '@/components/Placeholder.vue';
import Tabs from '@/components/Tabs.vue';
import { ref, watch } from 'vue';
import ModelRouterView from '@/components/ModelRouterView.vue';
import methods from '@/utils/methods';

export default {
    name: 'Users',
    components: {
        Main,
        ActionButton,
        CardList,
        Card,
        Placeholder,
        Tabs,
        ModelRouterView,
    },
    mixins: [methods],
    setup() {
        const { initState, setModalData } = useState();
        const { docs, isLoading, forceUnSubscribe } = useUnsubscribe(usersCollection.orderBy('firstname'));
        const users = ref([]);
        const admins = ref([]);

        watch(
            () => [...docs.value],
            async (list) => {
                isLoading.value = true;
                const allAdmins = await Promise.all(list.map(async (user) => {
                    const isGuestAdmin = await httpsRequest.httpsCallable('isUserAdmin');
                    const isGuestAdminResponse = await isGuestAdmin(user.id);
                    console.log(isGuestAdminResponse.data);
                    return { isAdmin: isGuestAdminResponse.data, ...user };
                }));

                users.value = allAdmins.filter((user) => !user.isAdmin);
                admins.value = allAdmins.filter((user) => user.isAdmin);

                isLoading.value = false;
            },
        );

        return { users, admins, isLoading, initState, setModalData, forceUnSubscribe };
    },
    data() {
        return {
            view: 'users',
            tabs: [
                { value: 'users', label: 'Brugere' },
                { value: 'admins', label: 'Administratorer' },
            ],
            filter: 'all',
            filters: [
                { value: 'all', label: 'Alle' },
                { value: 'Sparekassen Danmark', label: 'Sparekassen Danmark' },
                { value: 'Sparekassen Thy', label: 'Sparekassen Thy' },
                { value: 'Sociale Medier', label: 'Sociale Medier' },
                { value: 'Venner/familie', label: 'Venner/familie' },
                { value: 'Andet', label: 'Andet' },
            ],
        };
    },
    computed: {
        filteredUsers() {
            return this.users.filter((user) => this.filter === 'all' || user.referral === this.filter);
        },
    },
    methods: {
        async loginAsUser(uid) {
            try {
                const adminUid = auth.currentUser.uid;
                const createCustomToken = await httpsRequest.httpsCallable('createCustomToken');
                const response = await createCustomToken(uid);
                const userCredential = await auth.signInWithCustomToken(response.data.token);

                this.forceUnSubscribe();
                await this.initState(userCredential.user.uid, adminUid);
                // const routeData = this.$router.push('/');
                // window.open(routeData.href, '_blank');
                await this.$router.push('/');
            } catch (err) {
                console.log(err);
                console.log('Kunne ikke logge ind som bruger');
            }
        },
        viewUser(user) {
            const data = this.removeNullValues(user);
            this.setModalData(data);
            this.$router.push({ name: 'view-user', params: { id: user.id } });
        },
    },
};
</script>

<style scoped lang="less">
@import '../../assets/css/variables';

@media @laptop-screen {
    ul[class*=columns-] li.card.column-1 {
        grid-column: auto;
    }
}
</style>
